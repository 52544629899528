import React from 'react';
import Carousel from 'react-multi-carousel';
import { useIntl } from 'gatsby-plugin-intl';

import Testimony from '../testimony';
import Section from '../section';

import imgOne from '../../assets/images/Testimonial_1.png';
import imgTwo from '../../assets/images/Testimonial_2.png';
import imgThree from '../../assets/images/testimonial_3.png';

interface Props {
  id?: string;
}

const TestimonySection = ({ id }: Props) => {
  const intl = useIntl();
  return (
    <Section
      className="testimonies"
      tagline={intl.formatMessage({ id: 'index.testimonies.tagline' })}
      title={intl.formatMessage({ id: 'index.testimonies.title' })}
      id={id}
    >
      <div className="m-carousel">
        <Carousel
          showDots={true}
          responsive={{
            tablet: {
              breakpoint: { min: 960, max: 8000 },
              items: 2,
            },
            mobile: {
              breakpoint: { max: 960, min: 0 },
              items: 1,
            },
          }}
        >
          <Testimony
            name={intl.formatMessage({
              id: 'index.testimonies.testimony_1.name',
            })}
            position={intl.formatMessage({
              id: 'index.testimonies.testimony_1.position',
            })}
            quote={intl.formatMessage({
              id: 'index.testimonies.testimony_1.quote',
            })}
            imageUrl={imgOne}
          />

          <Testimony
            name={intl.formatMessage({
              id: 'index.testimonies.testimony_2.name',
            })}
            position={intl.formatMessage({
              id: 'index.testimonies.testimony_2.position',
            })}
            quote={intl.formatMessage({
              id: 'index.testimonies.testimony_2.quote',
            })}
            imageUrl={imgTwo}
          />

          <Testimony
            name={intl.formatMessage({
              id: 'index.testimonies.testimony_3.name',
            })}
            position={intl.formatMessage({
              id: 'index.testimonies.testimony_3.position',
            })}
            quote={intl.formatMessage({
              id: 'index.testimonies.testimony_3.quote',
            })}
            imageUrl={imgThree}
          />
        </Carousel>
      </div>
    </Section>
  );
};

export default TestimonySection;
