import React from 'react';

export type Props = {
  index: number;
  title: string;
  subtitle: string;
  description: string;
  imgSrc: string;
  className?: string;
};
const Step = ({
  title,
  subtitle,
  index,
  imgSrc,
  description,

  className = null,
}: Props) => {
  return (
    <article className={className ? `m-step--image ${className}` : 'm-step'}>
      <div className="m-step--image__img-wrapper">
        <img className="m-step--image__img-wrapper__img" src={imgSrc} />
      </div>
      <div className="m-step--image__text-wrapper">
        <h4 className="m-step--image__text-wrapper__title">
          {`0${index + 1} ${title}`}
        </h4>
        <h4 className="m-step--image__text-wrapper__subtitle">{subtitle}</h4>
        <p className="m-step--image__text-wrapper__description">
          {description}
        </p>
      </div>
    </article>
  );
};

export default Step;
