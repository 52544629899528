import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { FormikHelpers } from 'formik';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import queryString from 'query-string';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Section from '../components/section';
import TestimonySection from '../components/sections/testimonySection';
import Step from '../components/step';
import StepWithImage from '../components/stepWithImage';
import Carousel from 'react-multi-carousel';
import Accordion from '../components/accordion';
import PriceCard from '../components/priceCard';
import api from '../api';

import bexioDeeptrueImg from '../assets/images/bexio&dt.svg';
import videoPoster from '../assets/images/Video.jpg';
import videoBexioMp4En from '../assets/video/Bexio_en.mp4';
import videoBexioWebmEn from '../assets/video/Bexio_en.webm';
import LegalsBriefing, { FormValues } from '../components/Forms/LegalsBriefing';

import 'react-multi-carousel/lib/styles.css';

type BexioPageProps = {
  pageContext: {
    language: string;
  };
  data: any;
  location: {
    search: string;
  };
};

// NOTE for now we have only en version of video
const videos = {
  en: {
    mp4: videoBexioMp4En,
    webm: videoBexioWebmEn,
  },
  de: {
    mp4: videoBexioMp4En,
    webm: videoBexioWebmEn,
  },
  it: {
    mp4: videoBexioMp4En,
    webm: videoBexioWebmEn,
  },
  fr: {
    mp4: videoBexioMp4En,
    webm: videoBexioWebmEn,
  },
};

const BexioPage = (props: BexioPageProps) => {
  const [isVerifyingLegals, setIsVeryfingLegals] = useState(false);
  const bexioPage = props.data.allPrismicBexioLanding.nodes[0].data;

  const { allPrismicBriefing, allPrismicApiResponses } = props.data;

  const { faq_questions } = bexioPage;
  const legalsContext = allPrismicBriefing.nodes[0].data;
  const apiResponses = allPrismicApiResponses.nodes[0].data;

  const handleConnectWithBexio = () => {
    api.bexioApi.getBexioLoginRedirectUrl().then(({ data }: any) => {
      location.replace(data.redirect);
    });
  };

  const { code: bexioValidationCode } = queryString.parse(
    props.location.search
  );

  useEffect(() => {
    if (bexioValidationCode) {
      setIsVeryfingLegals(true);
    }
  }, []);

  useEffect(() => {
    const player = document.querySelector('.bexio__video video');
    const threshold = 0.25;
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].intersectionRatio >= threshold) {
          player.play();
        } else {
          player.pause();
        }
      },
      {
        threshold: 0.25,
      }
    );

    observer.observe(player);
  }, []);

  const createBexioProject = (
    values: FormValues,
    { setSubmitting, setErrors }: FormikHelpers<FormValues>
  ) => {
    const data = {
      terms_agreement: values.terms_agreement,
      privacy_policy_accepted: values.privacy_policy_accepted,
      code: bexioValidationCode.toString(),
    };

    api.bexioApi
      .createAccount({ data })
      .then(({ data }) => {
        return location.replace(data.redirect_url);
      })
      .catch(() => {
        setErrors({
          non_field_errors: apiResponses.server_error,
        });

        setSubmitting(false);
      });
  };

  return (
    <Layout lang={props.pageContext.language}>
      <SEO title="Bexio" lang={props.pageContext.language} />

      <div className="bexio">
        {isVerifyingLegals ? (
          <div className="hero hero--product">
            <div className="legals-container">
              <LegalsBriefing
                context={legalsContext}
                onSubmit={createBexioProject}
                initialValues={{
                  terms_agreement: false,
                  privacy_policy_accepted: false,
                }}
              />
            </div>
          </div>
        ) : (
          <>
            {' '}
            <Section className="hero hero--product">
              <div className="hero__wrap">
                <div className="hero__content">
                  <h1 className="hero__title hero__title--centered">
                    {bexioPage.page_title}
                  </h1>
                  <p className="hero__description">{bexioPage.page_headline}</p>
                  <p className="hero__emphasis">{bexioPage.price_label}</p>
                  <button
                    className="hero__button"
                    onClick={handleConnectWithBexio}
                  >
                    {bexioPage.page_cta_label}
                  </button>
                </div>
              </div>
            </Section>
            <Section className="bexio__video">
              <video loop poster={videoPoster} muted preload="auto">
                <source
                  src={videos[props.pageContext.language].mp4}
                  type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'
                />
                <source
                  src={videos[props.pageContext.language].webm}
                  type="video/webm"
                />
                {/* <source
              src={videos[props.pageContext.language].ogg}
              type="video/ogg"
            /> */}
              </video>
            </Section>
            <Section
              tagline={bexioPage.advantages_title}
              title={bexioPage.advantages_subtitle}
              className="bexio__advantages"
              id="advantages"
            >
              <img
                className="bexio__advantages__integration-img"
                src={bexioDeeptrueImg}
                alt="bexio - deeptrue integration"
              />

              <div className="bexio__advantages-items">
                {bexioPage.advantages_list.map((adv: any) => {
                  return (
                    <Step
                      key={`bexio-advantage-item-${adv.item_name}`}
                      className="bexio__advantages-item"
                      title={adv.item_name}
                      content={adv.item_description.text}
                    />
                  );
                })}
              </div>
            </Section>
            <Section
              title={bexioPage.how_works_title}
              className="bexio__how"
              tagline={bexioPage.how_works_tagline}
              id="how_works"
            >
              {bexioPage.how_works_list.map((howItem: any, idx: any) => {
                const { asset } = howItem;
                return (
                  <StepWithImage
                    key={`how-step-item-${idx}`}
                    title={howItem.how_works_item_title}
                    subtitle={howItem.how_works_item_headline}
                    description={howItem.how_works_item_description.text}
                    index={idx}
                    imgSrc={asset.url}
                  />
                );
              })}
            </Section>
            <TestimonySection id="testimonials" />
            <Section
              className="bexio__pricing pricing__cards"
              tagline={bexioPage.pricing_title}
              title={bexioPage.pricing_headline}
              id="pricing"
            >
              <div className="m-carousel">
                <Carousel
                  showDots={true}
                  responsive={{
                    desktop: {
                      breakpoint: { max: 8000, min: 1200 },
                      items: 3,
                    },
                    tablet: {
                      breakpoint: { min: 960, max: 1200 },
                      items: 3,
                    },
                    mobile: {
                      breakpoint: { max: 960, min: 700 },
                      items: 2,
                    },
                    small: {
                      breakpoint: { max: 700, min: 0 },
                      items: 1,
                    },
                    x_small: {
                      breakpoint: { max: 480, min: 0 },
                      items: 1,
                    },
                  }}
                >
                  {bexioPage.body.map((plan: any, idx: number) => {
                    return (
                      <PriceCard
                        key={`bexio-price-plan-${idx}`}
                        name={plan.primary.plan_name}
                        description={plan.primary.plan_description}
                        period={plan.primary.plan_period}
                        price={plan.primary.plan_cost}
                        priceAfterDiscount={plan.primary.price_after_discount}
                        btnCta={handleConnectWithBexio}
                        btnCtaText={bexioPage.page_cta_label}
                        planDiscountDescription={
                          plan.primary.plan_discount_description
                        }
                        planDiscountInfo={plan.primary.plan_discount_info}
                        features={[
                          ...plan.items.map((item: any) => ({
                            content: item.plan_feature_name,
                            available: item.is_available,
                          })),
                        ]}
                      />
                    );
                  })}
                </Carousel>
              </div>
              <p className="bexio__pricing-footnote">
                {bexioPage.pricing_footnote}
              </p>
            </Section>
            <Section
              className="bexio__faq dt-faq__list"
              title={bexioPage.faq_subtitle}
              tagline={bexioPage.faq_title}
              id="faq"
            >
              <div className="dt-faq__group">
                {faq_questions.map(
                  (singleQuestion: { question: string; answer: string }) => (
                    <Accordion
                      key={singleQuestion.question}
                      question={singleQuestion.question}
                      answer={singleQuestion.answer}
                    />
                  )
                )}
              </div>
            </Section>
          </>
        )}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query BexioPage($prismicLang: String) {
    allPrismicBexioLanding(filter: { lang: { eq: $prismicLang } }) {
      nodes {
        _previewable
        data {
          advantages_subtitle
          advantages_title
          faq_subtitle
          faq_title
          how_works_tagline
          how_works_title
          page_cta_label
          page_headline
          page_title
          price_label
          pricing_description
          pricing_footnote
          pricing_headline
          pricing_title
          faq_questions {
            answer
            question
          }
          menu_links {
            anchor
            name
          }
          advantages_list {
            item_name
            item_description {
              text
            }
          }
          how_works_list {
            how_works_item_title
            how_works_item_headline
            how_works_item_description {
              text
            }
            asset {
              url
            }
          }
          body {
            ... on PrismicBexioLandingDataBodyPricingPlan {
              id
              primary {
                plan_cost
                plan_description
                plan_discount_description
                plan_discount_info
                plan_name
                plan_period
                price_after_discount
                pricing_cta
                pricing_plan_slug
              }
              slice_label
              slice_type
              items {
                is_available
                plan_feature_name
              }
            }
          }
        }
      }
    }
    allPrismicApiResponses(filter: { lang: { eq: $prismicLang } }) {
      nodes {
        _previewable
        data {
          server_error
        }
      }
    }
    allPrismicBriefing(filter: { lang: { eq: $prismicLang } }) {
      nodes {
        _previewable
        data {
          terms_and_conditions
          privacy_policy
          legal_terms_desc
          legal_consent
          i_agree_text
          create_project
        }
        lang
      }
    }
    allPrismicFaqsPage(filter: { lang: { eq: $prismicLang } }) {
      edges {
        node {
          _previewable
          id
          lang
          data {
            description {
              html
            }
            headline
            title
            body {
              ... on PrismicFaqsPageDataBodySection {
                id
                primary {
                  section_title
                }
                items {
                  question
                  answer_content {
                    html
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

/* eslint-disable-next-line */
// @ts-ignore eslint-disable-line
export default withPrismicPreview(BexioPage);
